/* src/modules/client/layout/Topbar.css */
.topbar {
    background-color: transparent;
    position: fixed;
    width: 100%;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
  }
  
  .topbar-menu {
    background-color: transparent;
    flex: 1;
    justify-content: center;
    display: flex;
  }

  .topbar .ant-menu-item span{
    color: white;
  }
  
  @media (max-width: 768px) {
    .topbar {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .topbar-menu {
      justify-content: flex-start;
      width: 100%;
    }
  
    .ant-menu-horizontal {
      border: none;
      background: transparent;
    }
  }
  